<template>
    <li class="nav-item dropdown ">
        <a class="nav-link" @click="cargardatos()" data-toggle="dropdown" href="#">
            <i class="fa fa-envelope"></i>
            <span class="badge badge-danger ">{{numero_correos}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-right" style="height: auto; overflow-x: hidden; min-width: 250px;">
            <ListaCorreos :correos="correos" />
        </div>
    </li>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
import ListaCorreos from './CorreosLista.vue';


export default({
    components:{
        ListaCorreos
    },
    data() {
        return {
            correos: [],
            numero_correos: '0',
        }
    },
    methods: {
        async cargardatos() {
            /* get  modulos/pwgsapi/index.php/correos/publicos */
            const api = new PwgsApi;
            let totalcorreos = 0;
            const datos = await api.get('correos/publicos');
            this.correos = datos.datos;
            for (let i = 0; i < this.correos.length; i++) { totalcorreos = parseInt(this.correos[i].n_nuevos) + parseInt(totalcorreos); }
            this.numero_correos = totalcorreos;                
        },       

    },
    mounted() {
        this.cargardatos();
    }
})
</script>
